// New page
import React from 'react'
import Gallery from '../components/Gallery'
const Galerija = () => {
  return (
    <div>
    <Gallery/>
    </div>

  )
}

export default Galerija